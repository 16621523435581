import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styles from './BlogPost.module.scss'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core'

export default class BlogPost extends PureComponent {
  static propTypes = {
    fluid: PropTypes.object.isRequired,
    alt: PropTypes.string,
    description: PropTypes.string
  }

  render() {
    return (
      <Card className={styles.card}>
        <CardActionArea>
          <Img
            className={styles.projectImage}
            backgroundColor="#6b7f88"
            fluid={this.props.fluid}
            alt={this.props.alt}
          />
          <CardContent>
            <h4>
              {this.props.alt}
            </h4>
            <p>
              {this.props.description}
            </p>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
}
